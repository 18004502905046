<template>
    <div class="content-wrapper">
        <div class="content">
            <OnBoarding SelectCRMSystem></OnBoarding>
            <div class="connectorsCardsLayout" v-if="currentTimezone">
                <ConnectorSettingsCard
                    class="pa-2"
                    v-for="(connector, index) in availableCrmSystems"
                    :key="index"
                    :connector="connector"
                ></ConnectorSettingsCard>
            </div>
            <div v-else style="align-self: flex-start">
                <h1>Пожалуйста, выберите Ваш часовой пояс</h1>
            </div>
            <div
                class="whatsapp-settings-form__setting__value"
                :class="!currentTimezone ? 'unactive' : 'active'"
            >
                Часовой пояс: {{ currentTimezone ? currentTimezone : '' }}
                <TimeZonePicker
                    class="mt-2"
                    :value="value"
                    :recommendCurrentTimezone="true"
                    label="Выбрать"
                    @input="setTimezone"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { useProject } from '@/hooks/useProject'
import ConnectorSettingsCard from '@/components/project/onBoarding/ConnectorSettingsCard.vue'
import { getCurrentInstance, computed, ref } from 'vue'
import OnBoarding from './OnBoarding.vue'
import TimeZonePicker from '../../ui/TimeZonePicker.vue'

export default {
    name: 'SelectCRMSystem',

    components: {
        TimeZonePicker,
        OnBoarding,
        ConnectorSettingsCard,
    },

    setup() {
        const value = Object()
        const { project } = useProject()
        const root = getCurrentInstance().proxy
        const store = root.$store
        const connectorsList = store.getters.connectorsHashMapWithKeysByConnectorsType
        const currentTimezone = ref(null)

        const crmSystems = ['yClients', 'dikidi']

        const setTimezone = (timezone) => {
            currentTimezone.value = timezone
        }

        const getAvailableCrmSystems = (connectorsList) => {
            const availableCrmSystems = new Object()
            Object.keys(connectorsList)
                .filter((connecttorType) => crmSystems.includes(connecttorType))
                .forEach(
                    (connectorType) => (availableCrmSystems[connectorType] = connectorsList[connectorType])
                )
            return availableCrmSystems
        }

        const availableCrmSystems = computed(() => getAvailableCrmSystems(connectorsList))

        return {
            setTimezone,
            value,
            currentTimezone,
            availableCrmSystems,
        }
    },
}
</script>
<style lang="sass" scoped>
.connectorsCardsLayout
    display: flex
    justify-content: space-around

.whatsapp-settings-form__setting__value
    border-radius: 4px
    padding: 20px
    margin-top: 20px
    align-self: flex-start
    display: flex
    flex-direction: column
    align-items: flex-start

    .unactive
        border: 1px solid red
    .active
        border: 1px solid #2A3B511F

    .content-wrapper
        margin-top: 40px
    .content
        align-items: center
        justify-content: center
        display: flex
        flex-direction: column
        max-width: 81.25%
</style>